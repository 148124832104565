<template>
    <div
        :id="id"
        class="dn-select"
        :name="name"
    >
        <button
            ref="toggleButton"
            class="dn-select__trigger"
            type="button"
            :select-expanded="isExpanded"
            :disabled="disabled"
            @click="toggleDropdown()"
            @keydown.down.prevent="isExpanded = true"
        >
            <span class="dn-select__sr-only">{{ toggleSrLabel }}</span>
            <span class="dn-select__trigger__content">
                <template v-if="isMultiselect">
                    <ul
                        v-if="value.length > 0"
                        class="dn-select__list-selected-options"
                    >
                        <li
                            v-for="(optionValue, index) in value"
                            :key="index"
                            class="dn-select__list-selected-option"
                            @click.stop="handleUserInput(optionValue)"
                        >
                            <slot
                                name="selectedOptionButton"
                                :label="getLabelByValue(optionValue)"
                                :sr-label="deselectSrLabel"
                            >
                                <button
                                    type="button"
                                >
                                    <span class="dn-select__sr-only">
                                        {{ deselectSrLabel }}: 
                                    </span>
                                    <slot
                                        name="selectedOption"
                                        :label="getLabelByValue(optionValue)"
                                    >
                                        {{ getLabelByValue(optionValue) }}
                                    </slot>
                                </button>
                            </slot>
                        </li>
                    </ul>
                    <span
                        v-else
                        class="dn-select__placeholder"
                    >
                        {{ placeholder }}
                    </span>
                </template>
                <span
                    v-else
                    class="dn-select__selected"
                >
                    <span class="dn-select__sr-only">{{ selectedSrLabel }}:</span>
                    {{ getLabelByValue(value) }}
                </span>
                <slot name="dropdownIcons">
                    <span class="dn-select__icon">
                        <slot
                            v-if="isExpanded"
                            name="DropdownIconExpanded"
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"
                                />
                            </svg>
                        </slot>
                        <slot
                            v-else
                            name="DropdownIconCollapsed"
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M7.41,8.59L12,13.17L16.59,8.59L18,10L12,16L6,10L7.41,8.59Z"
                                />
                            </svg>
                        </slot>
                    </span>
                </slot>
            </span>
        </button>

        <ul
            v-show="isExpanded"
            ref="optionsList"
            class="dn-select__list"
            role="listbox"
            :aria-hidden="isExpanded ? 'false' : 'true'"
            @focusout="onFocusOut"
        >
            <li
                v-for="(item, index) in options"
                :key="index"
                ref="options"
                class="dn-select__list__item"
                :value-selected="isValueSelected(item.value)"
                role="option"
                :tabindex="0"
                :aria-selected="isValueSelected(item.value)"
                @click="handleUserInput(item.value)"
                @keydown.enter="handleUserInput(item.value)"
                @keydown.space.prevent="handleUserInput(item.value)"
            >
                {{ item.label }}
            </li>
        </ul>
    </div>
</template>

<script>
const KEY_ARROW_DOWN = 40;
const KEY_ARROW_UP = 38;
const KEY_ESCAPE = 27;

export default {
    name: 'DnSelect',
    props: {
        modelValue: {
            type: [Array, String, Number],
            default: undefined,
        },

        id: {
            type: [String],
            default: undefined,
        },

        name: {
            type: [String],
            default: undefined,
        },

        options: {
            // [{label: '', value: ''}, {label: '', value: ''}]
            type: Array,
            required: true,
        },

        isMultiselect: {
            type: Boolean,
            default: false,
        },

        placeholder: {
            type: String,
            default: 'Select an option',
        },

        required: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        deselectSrLabel: {
            type: String,
            default: 'Deselect',
            required: true,
        },

        selectedSrLabel: {
            type: String,
            default: 'Selected',
            required: true,
        },

        toggleSrLabel: {
            type: String,
            default: 'Select an option',
            required: true,
        },
    },

    emits: ['update:modelValue'],

    data() {
        return {
            isExpanded: false,
        };
    },

    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(newValue) {
                this.$emit('update:modelValue', newValue);
            },
        },
    },

    watch: {
        isExpanded(newValue) {
            if (newValue) {
                window.addEventListener('click', this.onOutsideClick);
                window.addEventListener('keyup', this.handleKeyEvent);
            } else {
                window.removeEventListener('click', this.onOutsideClick);
                window.removeEventListener('keyup', this.handleKeyEvent);
            }
        },

        modelValue(newModelValue) {
            this.validateModelValueProp(newModelValue);
        },
    },

    created() {
        this.validateModelValueProp(this.modelValue);
    },

    beforeUnmount() {
        window.removeEventListener('click', this.onOutsideClick);
        window.removeEventListener('keyup', this.handleKeyEvent);
    },

    methods: {
        toggleDropdown() {
            this.isExpanded = !this.isExpanded;
        },

        getLabelByValue(optionValue) {
            const option = this.options.find(
                item => item.value === optionValue
            );

            return option ? option.label : '';
        },

        isValueSelected(optionValue) {
            if (this.isMultiselect) {
                return this.value.includes(optionValue);
            } else {
                return this.value === optionValue;
            }
        },

        handleUserInput(optionValue) {
            if (this.disabled) {
                return;
            }

            if (this.isMultiselect) {
                return this.handleMultiSelectDropdown(optionValue);
            }

            this.handleSingleSelectDropdown(optionValue);
        },

        handleSingleSelectDropdown(optionValue) {
            this.isExpanded = false;
            this.$emit('update:modelValue', optionValue);
        },

        handleMultiSelectDropdown(optionValue) {
            const newModel = [...this.value];
            const index = this.value.indexOf(optionValue);

            if (index === -1) {
                // If the option isn't present in the selected items yet, add it
                newModel.push(optionValue);
            } else {
                // If it is already selected, remove it
                newModel.splice(index, 1);
            }

            this.$emit('update:modelValue', newModel);
        },

        handleKeyEvent(e) {
            switch (e.keyCode) {
            case KEY_ARROW_DOWN:
                e.preventDefault();
                if (this.$refs.options.includes(e.target)) {
                    e.target.nextElementSibling?.focus();
                } else {
                    this.$refs.options[0].focus();
                }
                break;
            case KEY_ARROW_UP:
                e.preventDefault();
                e.target.previousElementSibling?.focus();
                break;
            case KEY_ESCAPE:
                e.preventDefault();
                this.isExpanded = false;
                break;
            }
        },

        onFocusOut(event) {
            // If the list is focussed out by the dropdown trigger button, do nothing since the button event will handle the toggling
            if (
                event.relatedTarget &&
                event.relatedTarget.classList.contains('dn-select__trigger')
            ) {
                return;
            }

            if (!this.$refs.optionsList?.contains(event.relatedTarget)) {
                this.isExpanded = false;
            }
        },

        onOutsideClick(event) {
            // If the click is outside the dropdown, close it
            if (
                !this.$refs.toggleButton?.contains(event.target) &&
                !this.$refs.optionsList?.contains(event.target)
            ) {
                this.isExpanded = false;
            }
        },

        validateModelValueProp(newModelValue) {
            if (this.isMultiselect === true && !Array.isArray(newModelValue)) {
                // eslint-disable-next-line no-console
                console.error(
                    '[@digitalnatives/select] When using a multiselect dropdown, value must be an array.'
                );
            } else if (
                this.isMultiselect === false &&
                typeof newModelValue !== 'string' &&
                typeof newModelValue !== 'number'
            ) {
                // eslint-disable-next-line no-console
                console.error('[@digitalnatives/select] Value must be a string or a number.');
            }
        },
    },
};
</script>

<style lang="less" src="./select.less" />
